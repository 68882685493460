import { sendAndHandleSdkError } from '../internal/communication';
import { ensureInitialized } from '../internal/internalAPIs';
import { errorNotSupportedOnPlatform, FrameContexts } from './constants';
import { runtime } from './runtime';

export namespace math {
  export interface MathProps {
    number1: number;
    number2: number;
  }

  export interface Math {
    result: number;
  }

  /**
   * Returns the sum of two numbers
   *
   * @param props {@link MathProps} - Input numbers
   * @returns Promise that will be fulfilled when the operation has completed
   */
  export function getSum(props: MathProps): Promise<Math> {
    return new Promise<Math>((resolve) => {
      ensureInitialized(runtime, FrameContexts.content, FrameContexts.task);
      if (!isSupported()) {
        throw errorNotSupportedOnPlatform;
      }
      resolve(sendAndHandleSdkError('math.getSum', props));
    });
  }

  export function isSupported(): boolean {
    return ensureInitialized(runtime) && runtime.supports.math ? true : false;
  }
}
