import React from "react";
import {
  Image,
  ImageFit,
  Spinner,
  SpinnerSize,
  Text,
  Stack,
  FontIcon,
  useTheme
} from "@fluentui/react";

type ImageAreaProps = {
  imageUrl?: string;
  showProcessing?: boolean;
  pickItemText?: string;
  style?: React.CSSProperties;
};

export const ImageArea: React.FunctionComponent<ImageAreaProps> = (props) => {
  const theme = useTheme();

  const getContent = () => {
    if (props.showProcessing === true) {
      return <Spinner style={{ height: "100%" }} size={SpinnerSize.large} />;
    }

    if (props.imageUrl === undefined) {
      return (
        <Stack style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
          <FontIcon iconName="Picture" style={{fontSize: '48pt', color: theme.palette.neutralSecondaryAlt}}/>
          <Text style={{fontSize: '14pt', marginTop: '5px', color: theme.palette.neutralSecondaryAlt}}>{props.pickItemText}</Text>
        </Stack>
      );
    }

    return <Image style={{borderRadius: theme.effects.roundedCorner6}} src={props.imageUrl} imageFit={ImageFit.contain} />;
  };

  let rootStyle = {
    boxShadow: theme.effects.elevation4,
    backgroundColor: theme.semanticColors.bodyStandoutBackground,
    borderRadius: theme.effects.roundedCorner6
  };
  Object.assign(rootStyle, props.style);
  return <div style={rootStyle}>{getContent()}</div>;
};
