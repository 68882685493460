import React, { useState, useEffect } from "react";
import { Stack, Text } from "@fluentui/react";
import "./App.css";
import { ImageArea } from "./ImageArea";
import {
  checkNativeCpuEngineAvailable,
  checkNativeCudaEngineAvailable,
  checkWasmEngineAvailable,
  checkWebGlEngineAvailable,
  createNativeCpuEngine,
  createNativeCudaEngine,
  createWasmEngine,
  createWebGlEngine,
} from "./inference/onnxEngine";
import {
  checkEdgeCpuEngineAvailable,
  checkEdgeNpuEngineAvailable,
  createEdgeCpuEngine,
  createEdgeNpuEngine,
} from "./inference/edgeOnnxEngine";
import {
  checkSnpeSrCpuEngineAvailable,
  checkSnpeSrNpuEngineAvailable,
  checkSnpeSrAmlEngineAvailable,
  createSnpeSrCpuEngine,
  createSnpeSrNpuEngine,
  createSnpeSrAmlEngine
} from "./inference/snpeSrEngine";
import { checkAmlEngineAvailable, createAmlEngine } from "./inference/amlEngine";
import { BackendSelectionPanel } from "./BackendSelectionPanel";
import { ImageSelectionPanel } from "./ImageSelectionPanel";
import { IInferenceEngine } from "./inference/IInferenceEngine";
import { app } from '@microsoft/teams-js';
import { metaOSRouter } from "./MetaOSRouter";

const engineFactories: { [index in string]: {checkAvailable: () => Promise<boolean>, create: () => IInferenceEngine} } = {
  cpu: { checkAvailable: checkNativeCpuEngineAvailable, create: createNativeCpuEngine },
  snpesrcpu: { checkAvailable: checkSnpeSrCpuEngineAvailable, create: createSnpeSrCpuEngine },
  edgecpu: { checkAvailable: checkEdgeCpuEngineAvailable, create: createEdgeCpuEngine },
  cuda: { checkAvailable: checkNativeCudaEngineAvailable, create: createNativeCudaEngine },
  wasm: { checkAvailable: checkWasmEngineAvailable, create: createWasmEngine },
  webgl: { checkAvailable: checkWebGlEngineAvailable, create: createWebGlEngine },
  snpesrnpu: { checkAvailable: checkSnpeSrNpuEngineAvailable, create: createSnpeSrNpuEngine },
  edgenpu: { checkAvailable: checkEdgeNpuEngineAvailable, create: createEdgeNpuEngine },
  aml: { checkAvailable: checkAmlEngineAvailable, create: createAmlEngine },
  snpesraml: { checkAvailable: checkSnpeSrAmlEngineAvailable, create: createSnpeSrAmlEngine },
};
type EngineType = keyof typeof engineFactories;

const enginePriorityMap: { [index in string]: EngineType[] } = {
  wasm: ["wasm"],
  cpu: ["cpu", "snpesrcpu", "edgecpu"],
  //gpu: ["cuda", "webgl"],
  npu: ["snpesrnpu", "edgenpu"],
  aml: ["snpesraml", "aml"]
};
type InferenceTargetType = keyof typeof enginePriorityMap;

type TargetMapping = {
  [index in InferenceTargetType]?: IInferenceEngine;
};

async function getEngineAvailability(): Promise<{ [index in EngineType]?: boolean;}> {
  const engineAvailability: { [index in EngineType]?: boolean;} = {};

  const engineKeys: EngineType[] = [];
  const engineAvailabilityPromises: Promise<boolean>[] = [];
  for (const engine in engineFactories) {
    engineKeys.push(engine as EngineType);
    engineAvailabilityPromises.push(engineFactories[engine as EngineType].checkAvailable());
  }

  const engineAvailabilityResults = await Promise.all(engineAvailabilityPromises);

  for (let i = 0; i < engineAvailabilityResults.length; ++i) {
    engineAvailability[engineKeys[i]] = engineAvailabilityResults[i];
  }
  return engineAvailability;
}

export const App: React.FunctionComponent = () => {
  const [targets, setTargets] = useState<TargetMapping | undefined>(undefined);
  useEffect(() => {
    (async () => {
      const engineAvailability = await getEngineAvailability();

      const updatedTargets: TargetMapping = {};
      for (const target in enginePriorityMap) {
        for (const engine of enginePriorityMap[target as InferenceTargetType]) {
          if (engineAvailability[engine as EngineType] === true) {
            updatedTargets[target as InferenceTargetType] = engineFactories[engine as EngineType].create();
            break;
          }
        }
      }
      console.log(engineAvailability, updatedTargets);

      setTargets(updatedTargets);
    })();

    // Backwards compatability with older hub versions - must notify ready
    app.initialize().then(() => {
      app.notifyAppLoaded();
      app.notifySuccess();
      console.log("metaOS initialize ready notified");
      metaOSRouter.InitializeInferenceSession();
    }).catch((reason) => {
      console.log("metaOS initialization failed, " + reason.toString());
    });
  }, []);

  const [selectedTarget, setSelectedTarget] = useState<InferenceTargetType | undefined>(undefined);

  var processingStartTime: number | undefined;
  const [processingTimeResult, setProcessingTimeResult] = useState<string | undefined>(undefined);

  const [inputImage, setInputImage] = useState<string | undefined>(undefined);
  const [outputImage, setOutputImage] = useState<
    { outputImage: string; sourceInfo?: { inputImage: string; target: InferenceTargetType } } | undefined
  >(undefined);

  useEffect(() => {
    let cancelUpdate = false;

    (async () => {
      processingStartTime = Date.now();
      setOutputImage(undefined);
      setProcessingTimeResult(undefined);

      if (
        inputImage !== undefined &&
        targets !== undefined &&
        selectedTarget !== undefined
      ) {
        let upscaledImageDataUrl: string = "";
        let failed = false;
        try {
          console.log("attempting upscaling...");
          upscaledImageDataUrl = await targets[selectedTarget]!.runAsync(inputImage);
        } catch (e: any) {
          failed = true;
          console.log("Upscaling failed: " + e.toString());

          upscaledImageDataUrl = "";
        }

        if (!cancelUpdate) {
          setOutputImage({
            outputImage: upscaledImageDataUrl,
            sourceInfo: { inputImage: inputImage, target: selectedTarget },
          });

          if (failed === true) {
            console.log("Failed case");
            setSelectedTarget(undefined);
            setOutputImage(undefined);
            processingStartTime = undefined;
            setProcessingTimeResult(undefined);
          }
          else if (processingStartTime !== undefined) {
            console.log("Success case");
            var now = Date.now();
            var diff = (now - processingStartTime)/1000;
            setProcessingTimeResult(diff.toString());
            processingStartTime = undefined;
          }
          else {
            console.log("drop thru");
          }
        }
      } else {
        setOutputImage(undefined);
      }
    })();

    return () => 
    {
      cancelUpdate = true;
    };
  }, [inputImage, selectedTarget, targets]);

  const isOutputSteadyState = () => {
    if (inputImage === "" || inputImage === undefined || selectedTarget === undefined) {
      return true;
    }

    return outputImage?.sourceInfo?.inputImage === inputImage && outputImage?.sourceInfo?.target === selectedTarget;
  }

  return (
    <Stack horizontal style={{ display: "flex", justifyContent: "center", height: "100%" }}>
      <Stack horizontal className="titlebar" style={{ alignItems:"center" }}>
        <img src="icon.ico" style={{ height: "20px", marginLeft: "15px" }} alt="App icon"/>
        <Text style={{ color: "white", fontSize: "10pt", marginLeft: "10px" }}>Super Resolution</Text>
      </Stack>

      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          height: "100%",
          padding: "20px",
          maxWidth: "150vh",
          flex: "1",
        }}
      >
        <Stack style={{ margin: "20px", flex: "1", maxWidth: "40vw", minWidth: "360px" }}>
          <Text style={{ color: "white", fontSize: "16pt", marginBottom: "10px" }}>Original</Text>

          <ImageArea
            style={{
              aspectRatio: "1 / 1",
              maxWidth: "40vw",
              minWidth: "360px",
              marginBottom: "10px"
            }}
            showProcessing={inputImage === null}
            imageUrl={inputImage !== null ? inputImage : undefined}
            pickItemText='Pick an image below'
          />

          <ImageSelectionPanel onSelectedImageChanged={setInputImage} />
        </Stack>

        <Stack style={{ margin: "20px", flex: "1", maxWidth: "40vw", minWidth: "360px", position: "relative" }}>
          <Text style={{ color: "white", fontSize: "16pt", marginBottom: "10px" }}>Super Resolution</Text>
          <Text style={{ color: "white", fontSize: "14pt", textAlign: "right", position:"absolute", right: "0", top: "6px"}}>Time: {processingTimeResult === undefined ? "---" : processingTimeResult.toString()}</Text> 

          <ImageArea
            style={{
              aspectRatio: "1 / 1",
              maxWidth: "40vw",
              minWidth: "360px",
              marginBottom: "10px"
            }}
            showProcessing={!isOutputSteadyState()}
            imageUrl={outputImage?.outputImage}
            pickItemText='Pick a backend below'
          />

          <BackendSelectionPanel
            style={{ height: "100px" }}
            showCpu={targets?.cpu !== undefined}
            showGpu={targets?.gpu !== undefined}
            showNpu={targets?.npu !== undefined}
            showAml={targets?.aml !== undefined}
            selectedBackend={selectedTarget}
            onBackendClicked={(target) => {
               setSelectedTarget(target as InferenceTargetType);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
