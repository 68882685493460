import { sendAndHandleSdkError } from '../internal/communication';
import { ensureInitialized } from '../internal/internalAPIs';
import { errorNotSupportedOnPlatform, FrameContexts } from './constants';
import { runtime } from './runtime';

export namespace machineLearning {
  export interface SuperResolutionParams {
    inferenceType: number;
    width: number;
    height: number;
    stride: number;
    imageData: string;
  }

  export interface SegmentationParams {
    inferenceType: number;
    width: number;
    height: number;
    numChannels: number;
    imageData: Float32Array;
  }

  /**
   * Creates and initializes the inference session
   *
   * @returns Promise that will be fulfilled when the operation has completed
   */
  export function createInferenceSession(): Promise<void> {
    return new Promise<void>((resolve) => {
      ensureInitialized(runtime, FrameContexts.content, FrameContexts.task);
      if (!isSupported) {
        throw errorNotSupportedOnPlatform;
      }
      resolve(sendAndHandleSdkError('machineLearning.createInferenceSession'));
    });
  }

  /**
   * Runs the inference session with input feeds
   *
   * @param props {@link SuperResolutionParams}
   * @returns Promise that will be fulfilled when the operation has completed
   */
  export function runInferenceSession(data: SuperResolutionParams): Promise<string> {
    return new Promise<string>((resolve) => {
      ensureInitialized(runtime, FrameContexts.content, FrameContexts.task);
      if (!isSupported) {
        throw errorNotSupportedOnPlatform;
      }
      resolve(sendAndHandleSdkError('machineLearning.runInferenceSession', data));
    });
  }

  export function createSegmentationInferenceSession(): Promise<void> {
    return new Promise<void>((resolve) => {
      ensureInitialized(runtime, FrameContexts.content, FrameContexts.task);
      if (!isSupported) {
        throw errorNotSupportedOnPlatform;
      }
      resolve(sendAndHandleSdkError('machineLearning.createSegmentationInferenceSession'));
    });
  }

  export function runSegmentationInferenceSession(data: SegmentationParams): Promise<string> {
    return new Promise<string>((resolve) => {
      ensureInitialized(runtime, FrameContexts.content, FrameContexts.task);
      if (!isSupported) {
        throw errorNotSupportedOnPlatform;
      }
      resolve(sendAndHandleSdkError('machineLearning.runSegmentationInferenceSession', data));
    });
  }

  export function isSupported(): boolean {
    return ensureInitialized(runtime) && runtime.supports.machineLearning ? true : false;
  }
}
