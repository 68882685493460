import { machineLearning } from '@microsoft/teams-js';

interface DredgeInterface {
    InitializeInferenceSession(): void;
    UpscaleImage(inferenceType: number, width: number, height: number, stride: number, data: string): string;
}

class MetaOSRouter {
    constructor() {
        this._webView = (window as any).chrome?.webview;
    }

    public InitializeInferenceSession() {
        if (this.IsMetaOS()) {
            machineLearning.createInferenceSession();
        }
        else if (this.IsDredge()) {
            this.Dredge()?.InitializeInferenceSession();
        }
    }

    public UpscaleImageAsync(data: machineLearning.SuperResolutionParams): Promise<string | undefined> {
        return new Promise<string>(async (resolve, reject) => {
            var result: string | undefined = undefined;

            if (this.IsMetaOS()) {
                console.log("Running with MetaOS");
                result = await machineLearning.runInferenceSession(data);
            }
            else if (this.IsDredge()) {
                result = this.Dredge()?.UpscaleImage(data.inferenceType, data.width, data.height, data.stride, data.imageData);
            }
            
            if (result === undefined || result === "") {
                reject('No result');
            }
            else {
                resolve(result);
            }
        });
    }

    public IsMetaOS() : boolean {
        return machineLearning.isSupported();
    }

    private Dredge() : DredgeInterface {
        //@ts-ignore
        return this._webView?.hostObjects?.dredge;
    }

    public IsDredge() : boolean {
        return this.Dredge() !== undefined;
    }

    private _webView: object;
}

export const metaOSRouter = new MetaOSRouter();