import { createTheme } from "@fluentui/react";

export const appTheme = createTheme({
  palette: {
    themePrimary: "#7bd1fc",
    themeLighterAlt: "#05080a",
    themeLighter: "#142228",
    themeLight: "#253f4c",
    themeTertiary: "#4a7e97",
    themeSecondary: "#6db8de",
    themeDarkAlt: "#89d6fd",
    themeDark: "#9bdcfd",
    themeDarker: "#b5e5fe",
    neutralLighterAlt: "#2b2b2b",
    neutralLighter: "#333333",
    neutralLight: "#414141",
    neutralQuaternaryAlt: "#4a4a4a",
    neutralQuaternary: "#515151",
    neutralTertiaryAlt: "#6f6f6f",
    neutralTertiary: "#fdfdfd",
    neutralSecondary: "#fefefe",
    neutralPrimaryAlt: "#fefefe",
    neutralPrimary: "#fcfcfc",
    neutralDark: "#fefefe",
    black: "#ffffff",
    white: "#202020",
  },
  effects: {
      roundedCorner6: "8px"
  }
});
