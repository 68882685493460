import Jimp from "jimp";
import { clip, pixelToFloat, floatToPixel, arrayToImageData, imageDataToTensor } from "../shared/utility";
import { IInferenceEngine } from "./IInferenceEngine";

async function tensorToImageDataForNpu(data: Float32Array, width: number, height: number) {
  // Create output image to read tensor into
  const outputImage = await Jimp.read(width, height);

  var i = 0;
  // Populate each pixel of the output image with the output data
  outputImage.scan(
    0,
    0,
    outputImage.bitmap.width,
    outputImage.bitmap.height,
    function (_x, _y, idx) {
      this.bitmap.data[idx] = clip(floatToPixel(data[i]));
      this.bitmap.data[idx + 1] = clip(floatToPixel(data[i + 1]));
      this.bitmap.data[idx + 2] = clip(floatToPixel(data[i + 2]));
      this.bitmap.data[idx + 3] = 255;

      i += 3;
    }
  );

  return outputImage;
}

function imageDataToTensorForNpu(image: Jimp, dims: any): Float32Array {
  // Get buffer data from image and create R, G, and B arrays.
  var imageBufferData = image.bitmap.data;

  var transposedData: any;

  transposedData = new Int32Array(dims[1] * dims[2] * dims[3]);

  let idx = 0;
  for (let i = 0; i < imageBufferData.length; i += 4) {
    transposedData[idx++] = (imageBufferData[i]);
    transposedData[idx++] = imageBufferData[i + 1];
    transposedData[idx++] = imageBufferData[i + 2];
  }

  // convert to float32
  let i,
    l = transposedData.length; // length, we need this for the loop

  // create the Float32Array size 3 * 128 * 128 for these dimensions output
  const float32Data = new Float32Array(dims[1] * dims[2] * dims[3]);
  for (i = 0; i < l; i++) {
    float32Data[i] = pixelToFloat(transposedData[i]); // convert to float
  }

  return float32Data;
}

class EdgeOnnxEngine implements IInferenceEngine {
  constructor(backendType: string) {
    this._backendType = backendType;
    this._modelPath = EdgeOnnxEngine.getModelPath(this._backendType);
  }

  initializeAsync = async () => {
    if (this._initializePromise === undefined) {
      this._initializePromise = (async () => {
        console.log(`init edge inference session for model: ${this._modelPath}`);
        let model = await fetch(this._modelPath);
        let bufferForModel = await model.arrayBuffer();
        // @ts-ignore
        let ml = new NxtML();
        const context = await ml.createContext(
          {
            devicePreference: this._backendType,
            powerPreference: "low-power",
            numThread: 0,   // the default 0 means "decide automatically". 
            modelFormat: "onnx"
          });

        // @ts-ignore    
        let loader = new NxtMLModelLoader(context);
        this._model = await loader.load(bufferForModel);
      })();
    }
    await this._initializePromise;
  };

  runAsync = async (inputImageUrl: string) => {
    await this.initializeAsync();

    const image = await Jimp.read(inputImageUrl);
    const dims = [1, 3, 128, 128];
    let float32Data : unknown;
    if (this._backendType === 'npu') {
      float32Data = imageDataToTensorForNpu(image, dims);
    }
    else {
      float32Data = imageDataToTensor(image, dims);
    }

    var output = await this._model.compute(
      {
        lr_input: {
          data: float32Data,
          dimensions: dims
        }
      });

    const outputImage = this._backendType === 'npu' ?
      await tensorToImageDataForNpu(output.sr_output.data, output.sr_output.dimensions[2], output.sr_output.dimensions[3]) :
      await arrayToImageData(output.sr_output.data, output.sr_output.dimensions[2], output.sr_output.dimensions[3]);

    return await outputImage.getBase64Async(outputImage.getMIME());
  };

  static getModelPath(backendType: string) {
    return backendType === "npu" ? "./srgan_generator.onnx.enhanced.quant.onnx" : "./srgan_generator.onnx";
  }

  _backendType: string;
  _modelPath: string;
  _initializePromise?: Promise<void>;
  _model: any;
}

async function checkEdgeBackendAvailable(backend: string) {
  if (backend === "cpu") {
    // @ts-ignore
    return (typeof NxtML === 'function');
  }
  else if (backend === "npu") {
    // @ts-ignore
    if (typeof NxtML === 'function') {
      // @ts-ignore      
      return NxtML.isNpuSupported();
    }
    return false;
  }
  return false;
}

export async function checkEdgeCpuEngineAvailable() {
  return checkEdgeBackendAvailable("cpu");
}

export async function checkEdgeNpuEngineAvailable() {
  return checkEdgeBackendAvailable("npu");
}

export function createEdgeCpuEngine() {
  return new EdgeOnnxEngine("cpu");
}

export function createEdgeNpuEngine() {
  return new EdgeOnnxEngine("npu");
}
