import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stack, IconButton, Image, IStackStyles, Theme, ImageFit, useTheme } from "@fluentui/react";
import Jimp from "jimp";

async function downscaleImage(inputImageUrl: string) {
  const inputImage = await Jimp.read(inputImageUrl);
  inputImage.resize(128, 128);
  return await inputImage.getBase64Async(inputImage.getMIME());
}

export function getBaseScrollableStackStyle(
  theme: Theme,
  isScrollbarForcedVisible?: boolean,
  isScrollbarForcedFullyVisible?: boolean
): IStackStyles {
  return {
    root: {
      "::-webkit-scrollbar": {
        background: "rgba(255, 255, 255, 0)",
        border: "12px solid rgba(255, 255, 255, 0)",
        borderRadius: "6px",
        height: "12px",
      },
      // "::-webkit-scrollbar-button:single-button": {
      //     height: "8px",
      //     width: "8px",
      // },
      "::-webkit-scrollbar-thumb": {
        ":hover, :active": {
          border: "3px solid rgba(255, 255, 255, 0)",
          display: "block",
        },
        background: "rgba(255, 255, 255, 0.5442)",
        backgroundClip: "content-box",
        border: "5px solid rgba(255, 255, 255, 0)",
        borderRadius: "6px",
        display: isScrollbarForcedVisible || isScrollbarForcedFullyVisible ? "block" : "none",
      },
      ":active, :hover": {
        "::-webkit-scrollbar-thumb": {
          display: "block",
        },
      },
      overflowX: "scroll",
      overflowY: "hidden",
      marginBottom: "-12px",
    },
  };
}

type ImageSelectionPanelProps = {
  onSelectedImageChanged?: (image?: string) => void;
};

export const ImageSelectionPanel: React.FunctionComponent<ImageSelectionPanelProps> = (props) => {
  const theme = useTheme();
  const itemSize = "80px";

  const hiddenFileButtonRef = useRef<HTMLInputElement | null>(null);
  const onChooseInputImageClicked = useCallback((e: React.MouseEvent<IconButton, MouseEvent>) => {
    if (hiddenFileButtonRef.current !== null) {
      hiddenFileButtonRef.current.click();
    }
  }, []);

  const [inputImages, setInputImages] = useState<string[]>([]);

  const addInputImage = useCallback(async (inputImage: string) => {
    const downscaledImageDataUrl = await downscaleImage(inputImage);
    setInputImages((previous) =>
      previous.indexOf(downscaledImageDataUrl) === -1 ? previous.concat(downscaledImageDataUrl) : previous
    );
  }, []);

  const handleInputImageFileChange = useCallback(() => {
    const chosenFile = hiddenFileButtonRef.current?.files?.item(0);
    if (chosenFile !== null && chosenFile !== undefined) {
      const reader = new FileReader();
      reader.onload = async () => {
        addInputImage(reader.result as string);
      };
      reader.readAsDataURL(chosenFile);

      hiddenFileButtonRef.current!.value = "";
    }
  }, [addInputImage]);

  useEffect(() => {
    addInputImage("./DefaultImage0.png");
    addInputImage("./DefaultImage1.png");
    addInputImage("./DefaultImage2.png");
    addInputImage("./DefaultImage3.png");
    addInputImage("./DefaultImage4.png");
    addInputImage("./DefaultImage5.png");
    addInputImage("./DefaultImage6.png");
    addInputImage("./DefaultImage7.png");
  }, [addInputImage]);

  const [selectedImage, setSelectedImage] = useState<string | undefined>();

  const buildImageElements = useCallback(() => {
    const imageElements = [];
    let index = 0;
    for (let inputImage of inputImages) {
      imageElements.push(
        <IconButton
          key={index}
          toggle
          checked={inputImage === selectedImage}
          onClick={() => {
            setSelectedImage(inputImage);
          }}
          style={{ marginLeft: "10px" }}
          styles={{
            root: {
              height: itemSize,
              minWidth: itemSize,
              borderRadius: theme.effects.roundedCorner4,
              boxShadow: theme.effects.elevation8,
              color: theme.semanticColors.buttonText,
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
            rootHovered: {
              backgroundColor: theme.palette.themePrimary,
            },
            rootCheckedHovered: {
              backgroundColor: theme.palette.themePrimary,
            },
            rootPressed: {
              backgroundColor: theme.palette.themeTertiary,
            },
            rootChecked: {
              backgroundColor: theme.palette.themePrimary,
            },
          }}
        >
          <Image style={{ borderRadius: theme.effects.roundedCorner4 }} src={inputImage} imageFit={ImageFit.contain} />
        </IconButton>
      );
      index++;
    }
    return imageElements;
  }, [inputImages, selectedImage, theme]);

  useEffect(() => {
    if (props.onSelectedImageChanged !== undefined) {
      props.onSelectedImageChanged(selectedImage);
    }
  }, [selectedImage, props]);

  return (
    <Stack
      horizontal
      style={{
        height: "100px",
        borderRadius: theme.effects.roundedCorner6,
        backgroundColor: theme.semanticColors.bodyStandoutBackground,
        boxShadow: theme.effects.elevation4,
      }}
    >
      <Stack styles={getBaseScrollableStackStyle(theme, false, false)} horizontal style={{ maxWidth: "100%" }}>
        <Stack
          horizontal
          style={{
            height: "100%",
            scrollBehavior: "smooth",
            alignItems: "center",
          }}
        >
          <label htmlFor='hiddenFileButton'>
            <IconButton
              iconProps={{ iconName: "Add" }}
              styles={{
                root: {
                  height: itemSize,
                  width: itemSize,
                  borderRadius: theme.effects.roundedCorner4,
                  marginLeft: "10px",
                  boxShadow: theme.effects.elevation8,
                  color: theme.semanticColors.buttonText,
                  backgroundColor: theme.semanticColors.buttonBackgroundHovered,
                },
                rootHovered: {
                  color: theme.semanticColors.buttonText,
                  border: "4px solid",
                  borderColor: theme.semanticColors.accentButtonBackground,
                },
                rootPressed: {
                  color: theme.semanticColors.buttonText,
                },
              }}
              onClick={onChooseInputImageClicked}
            />
            <input
              id='hiddenFileButton'
              placeholder='Add input image'
              style={{ display: "none" }}
              ref={hiddenFileButtonRef}
              type='file'
              accept='image/*'
              onChange={handleInputImageFileChange}
            />
          </label>

          {buildImageElements()}
        </Stack>
      </Stack>
    </Stack>
  );
};
