import { Stack, PrimaryButton, FontIcon, DefaultButton, useTheme } from "@fluentui/react";

type BackendSelectionButtonProps = {
  iconName?: string;
  text?: string;
  style?: React.CSSProperties;
  checked?: boolean;
  onClick?: React.MouseEventHandler<PrimaryButton>;
};

const BackendSelectionButton: React.FunctionComponent<BackendSelectionButtonProps> = (props) => {
  const theme = useTheme();

  return (
    <DefaultButton
      styles={{
        root: {
          height: "80px",
          width: "100px",
          borderRadius: theme.effects.roundedCorner4,
          boxShadow: theme.effects.elevation8,
          color: theme.semanticColors.buttonText,
          backgroundColor: theme.semanticColors.buttonBackgroundHovered,
          border: "0px"
        },
        rootHovered: {
          color: theme.semanticColors.buttonText,
          border: "4px solid",
          borderColor: theme.semanticColors.accentButtonBackground,
        },
        rootCheckedHovered: {
          color: theme.semanticColors.accentButtonText,
          backgroundColor: theme.palette.themePrimary,
        },
        rootPressed: {
          backgroundColor: theme.palette.themeTertiary,
        },
        rootChecked: {
          color: theme.semanticColors.accentButtonText,
          backgroundColor: theme.palette.themePrimary,
        },
      }}
      toggle
      checked={props.checked}
      onClick={props.onClick}
      style={props.style}
    >
      {props.iconName ? <FontIcon iconName={props.iconName} style={{ marginRight: "5px" }} /> : <></>}
      {props.text}
    </DefaultButton>
  );
};

type BackendSelectionPanelProps = {
  selectedBackend?: string;
  onBackendClicked?: (backend: string) => void;
  showCpu?: boolean;
  showGpu?: boolean;
  showNpu?: boolean;
  showAml?: boolean;
  style?: React.CSSProperties;
};

const buildBackendSelectionButton = (
  props: BackendSelectionPanelProps,
  text: string,
  backend: string,
  iconName?: string
) => {
  return (
    <BackendSelectionButton
      iconName={iconName}
      text={text}
      style={{ margin: "3px" }}
      checked={props.selectedBackend === backend}
      onClick={() => {
        if (props.onBackendClicked !== undefined) {
          props.onBackendClicked(backend);
        }
      }}
    />
  );
};

export const BackendSelectionPanel: React.FunctionComponent<BackendSelectionPanelProps> = (props) => {
  const theme = useTheme();

  let rootStyle = {
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100px",
    borderRadius: theme.effects.roundedCorner6,
    backgroundColor: theme.semanticColors.bodyStandoutBackground,
    boxShadow: theme.effects.elevation4,
  };
  Object.assign(rootStyle, props.style);

  return (
    <Stack horizontal style={rootStyle}>
      {buildBackendSelectionButton(props, "WASM", "wasm")}
      {props.showCpu !== true ? <></> : buildBackendSelectionButton(props, "CPU", "cpu")}
      {props.showGpu !== true ? <></> : buildBackendSelectionButton(props, "GPU", "gpu")}
      {props.showNpu !== true ? <></> : buildBackendSelectionButton(props, "NPU", "npu")}
      {props.showAml !== true ? <></> : buildBackendSelectionButton(props, "Azure/AML", "aml")}
    </Stack>
  );
};
