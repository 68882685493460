import "./globals.js";
import { initializeIcons, ThemeProvider } from "@fluentui/react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { appTheme } from "./appTheme";

initializeIcons();

ReactDOM.render(
  <ThemeProvider theme={appTheme} style={{height: "100%"}}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
