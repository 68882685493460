import Jimp from 'jimp';
import { IInferenceEngine } from "./IInferenceEngine";
import { imageDataToTensor, arrayToImageData } from "../shared/utility";

async function responseObjectToImageData(responseData: any): Promise<Jimp> {
  // response is a json string, either with fields 'result' (an array) and 'time_in_sec' (a float) or just the field 'error'
  if ('error' in responseData) {
    throw new Error(`Response data error: ${responseData.error}`);
  }

  var testString: string = responseData.result;
  var b: any = Buffer.from(testString, 'base64').buffer;
  var data: Float32Array = new Float32Array(b);

  return await arrayToImageData(data, 512, 512);
}

async function postData(url: string, data: object | Buffer | undefined): Promise<any> {
  const request = new Request(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    body: JSON.stringify(data)
  })

  const response = await fetch(request);

  if (!response.ok) {
    throw new Error(`HTTP request failed: ${response.status}: ${response.statusText}`);
  }

  return await response.text();
}

class AmlEngine implements IInferenceEngine {
  initializeAsync = async () => {
    if (this._initializePromise === undefined) {
      this._initializePromise = new Promise<void>((resolve: () => void) => resolve());;
    }
    await this._initializePromise;
  };

  runAsync = async (inputImageUrl: string) => {
    await this.initializeAsync();

    const inputImage = await Jimp.read(inputImageUrl);

    const tensor = imageDataToTensor(inputImage, [1, 3, 128, 128]);

    // Send POST request to relative URL. React's proxy parameter will redirect this request
    // to the Azure endpoint.
    //const amlUrl = isElectron() ? 'http://8b367b95-2d66-44e5-90ba-3354e5304997.westus.azurecontainer.io/score' : '/score';
    const amlUrl = 'https://neonmetaosproxy.azurewebsites.net/api/ProcessImage';
    const responseData = await postData(amlUrl, Array.from(tensor));
    const responseObj = { result: responseData }
    const outputImage = await responseObjectToImageData(responseObj);
    return await outputImage.getBase64Async(outputImage.getMIME());
  };

  _initializePromise?: Promise<void>;
}

export async function checkAmlEngineAvailable() {
  return true;
}

export function createAmlEngine() {
  return new AmlEngine();
}
