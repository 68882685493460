//import isElectron from "is-electron";
import Jimp from "jimp";
import { IInferenceEngine } from "./IInferenceEngine";
import { metaOSRouter } from "../MetaOSRouter";
import { machineLearning } from "@microsoft/teams-js";

class SnpeSrEngine implements IInferenceEngine {
  constructor(inferenceType: number) {
    this._inferenceType = inferenceType;
  }

  initializeAsync = async () => {
    if (this._initializePromise === undefined) {
      console.log("init snpe engine");
      metaOSRouter.InitializeInferenceSession();
    }
    await this._initializePromise;
  };

  runAsync = async (inputImageUrl: string) => {
    await this.initializeAsync();

    console.log("Getting image")
    const inputImage = await Jimp.read(inputImageUrl);

    // Compute input image stride by getting the buffer index of pixel (0, 1).
    const inputWidth = inputImage.bitmap.width;
    const inputHeight = inputImage.bitmap.height;
    const inputStride = inputImage.getPixelIndex(0, 1);

    console.log("Calling Dredge...");
    var imageData = await inputImage.getBase64Async(inputImage.getMIME());
    const data: machineLearning.SuperResolutionParams = {
      inferenceType: this._inferenceType,
      width: inputWidth,
      height: inputHeight,
      stride: inputStride,
      imageData: imageData
    }
    return await metaOSRouter.UpscaleImageAsync(data) ?? "";
  };

  _initializePromise?: Promise<void>;
  _inferenceType: number = 0;
  _scale: number = 4;
}

async function checkNativeBackendAvailable(backend: number) { 
  // This works even when hosted in carrot, but should be made into a MetaOS API
  return metaOSRouter.IsDredge();
}

export async function checkSnpeSrCpuEngineAvailable() {
  return checkNativeBackendAvailable(0);
}

export async function checkSnpeSrNpuEngineAvailable() {
  return checkNativeBackendAvailable(1);
}

export async function checkSnpeSrAmlEngineAvailable() {
  //return checkNativeBackendAvailable(2);
  return false;
}

export function createSnpeSrCpuEngine() {
  return new SnpeSrEngine(0); // INFERENCE_TYPE_CPU
}

export function createSnpeSrNpuEngine() {
  return new SnpeSrEngine(1); // INFERENCE_TYPE_NPU
}

export function createSnpeSrAmlEngine() {
  return new SnpeSrEngine(2); // INFERENCE_TYPE_AML
}
