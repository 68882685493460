import Jimp from 'jimp';

export function sleep(delayMs: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delayMs));
}

export function clip(value: number, min: number = 0, max: number = 255) {
  return Math.min(Math.max(value, min), max);
}

export function pixelToFloat(pixel: number) {
  return (2.0 / 255.0) * pixel - 1.0;
}

export function floatToPixel(float: number) {
  return (255.0 / 2.0) * (float + 1.0);
}

export function imageDataToTensor(image: Jimp, dims: any): Float32Array {
  // 1. Get buffer data from image and create R, G, and B arrays.
  var imageBufferData = image.bitmap.data;
  const redArray = [];
  const greenArray = [];
  const blueArray = [];

  // 2. Loop through the image buffer and extract the R, G, and B channels
  for (let i = 0; i < imageBufferData.length; i += 4) {
    redArray.push(imageBufferData[i]);
    greenArray.push(imageBufferData[i + 1]);
    blueArray.push(imageBufferData[i + 2]);
    // skip data[i + 3] to filter out the alpha channel
  }

  // 3. Concatenate RGB to transpose [128, 128, 3] -> [3, 128, 128] to a number array
  const transposedData = redArray.concat(greenArray).concat(blueArray);

  // 4. convert to float32
  let l = transposedData.length; // length, we need this for the loop

  // create the Float32Array size 3 * 128 * 128 for these dimensions output
  const float32Data = new Float32Array(dims[1] * dims[2] * dims[3]);
  for (let i = 0; i < l; i++) {
    float32Data[i] = pixelToFloat(transposedData[i]); // convert to float
  }

  return float32Data;
}

export async function arrayToImageData(data: Float32Array, width: number, height: number): Promise<Jimp> {
  // 1. Create output image to read tensor into
  const outputImage = await Jimp.read(width, height);

  var rIdx = 0;
  var gIdx = outputImage.bitmap.width * outputImage.bitmap.height;
  var bIdx = gIdx * 2;

  // 2. Populate each pixel of the output image with the output data
  outputImage.scan(
    0,
    0,
    outputImage.bitmap.width,
    outputImage.bitmap.height,
    function (_x: number, _y: number, idx: number) {
      this.bitmap.data[idx] = clip(floatToPixel(data[rIdx]));
      this.bitmap.data[idx + 1] = clip(floatToPixel(data[gIdx]));
      this.bitmap.data[idx + 2] = clip(floatToPixel(data[bIdx]));
      this.bitmap.data[idx + 3] = 255;

      rIdx++;
      gIdx++;
      bIdx++;
    }
  );

  return outputImage;
}
